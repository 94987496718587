<template>
  <!-- <div>
    <v-card>
      <v-tabs v-model="tab" class="wizard-step ">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div>
                <h4 class="mb-10 font-weight-bold text-dark">
                  Karta malumotlari
                </h4>

                <v-text-field
                  disabled
                  label="Biriktiligan"
                  outlined
                  :value="getCorpCardDetails.owner_name"
                ></v-text-field>
                <v-text-field
                  disabled
                  outlined
                  label="Mashina raqami"
                  :value="getCorpCardDetails.owner_truck"
                ></v-text-field>
                <v-text-field
                  disabled
                  outlined
                  label="Karta raqami"
                  :value="getCorpCardDetails.number_16_digit"
                ></v-text-field>
                <v-text-field
                  disabled
                  outlined
                  label="Amal qilish muddati"
                  :value="getCorpCardDetails.expire"
                ></v-text-field
                ><v-text-field
                  disabled
                  outlined
                  label="Qoldiq"
                  :value="getCorpCardDetails.remain"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div>
                <div
                  class="d-flex justify-content-between align-items-center px-2"
                >
                  <div>
                    <v-card-title>
                      <h4 class=" font-weight-bold text-dark">
                        Korporativ karta operatsiyalar
                      </h4>

                      <v-spacer></v-spacer>
                    </v-card-title>
                  </div>
                  <div>
                    <v-dialog v-model="card_add" persistent max-width="50%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          v-model="addCard"
                          color="success"
                          class="mr-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Kartani Tuldirish
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline"
                          >Kartani Tuldirish</v-card-title
                        >

                        <v-card-text rd-text>
                          <v-combobox
                            :value="getCorpCardDetails.number_16_digit"
                            label="Karta raqami"
                            outlined
                            disabled
                            item-text="number_16_digit"
                            dense
                          ></v-combobox>
                          <v-col cols="12" :class="'p-0'" sm="6" md="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Sana"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="primary"
                                  @click="modal = false"
                                  >Bekor qilish</v-btn
                                >
                                <v-btn
                                  small
                                  color="success"
                                  @click="$refs.dialog.save(date)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          <v-text-field
                            label="Summa"
                            v-money="moneyDEPO"
                            v-model.lazy="amountDEPO"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="card_add = false"
                            >Yopish</v-btn
                          >
                          <v-btn
                            color="green darken-1"
                            text
                            @click="submit('DEPO')"
                            >Yuborish</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="card_remove" persistent max-width="50%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small color="red" dark v-bind="attrs" v-on="on">
                          Kartadan Yechish
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline"
                          >Kartadan Yechish</v-card-title
                        >
                        <v-card-text rd-text>
                          <v-combobox
                            :value="getCorpCardDetails.number_16_digit"
                            label="Karta raqami"
                            outlined
                            item-text="number_16_digit"
                            dense
                          ></v-combobox>

                          <v-col cols="12" :class="'p-0'" sm="6" md="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Sana"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="error"
                                  @click="modal = false"
                                  >Bekor qilish</v-btn
                                >
                                <v-btn
                                  small
                                  color="success"
                                  @click="$refs.dialog.save(date)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-text-field
                            label="Summa"
                            outlined
                            v-money="moneyWITH"
                            v-model.lazy="amountWITH"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="card_remove = false"
                            >Yopish</v-btn
                          >
                          <v-btn
                            color="green darken-1"
                            text
                            @click="submit('WITH')"
                            >Yuborish</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>

                comment place
                {{remamin}} 
                 <v-data-table
                  :headers="firstHeaders"
                  :items="remamin"
                  hide-default-footer
                  class="elevation-1"
                >
                </v-data-table> 

                comment place

                <div class="table__header">
                  <div class="table__header-title">
                    <p>
                      Kartani qoldig'i
                    </p>
                  </div>
                  <div class="table__header-value">
                    <p>
                      {{ getCorpCardDetails.remain }}
                    </p>
                  </div>
                </div>

                <v-data-table
                  :headers="headers"
                  :items="getAllOperByCard"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.operation_type`]="{ item }">
                    <div :class="item.operation_type_color">
                      <span
                        :class="item.operation_type_dot"
                        class="label label-dot"
                      ></span>
                      {{ item.operation_type }}
                    </div>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    {{ item.amount }}
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div> -->
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">Karta malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">Karta operatsiyalari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="text-primary text-center my-5">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </div>
        <div v-if="!loading" class="row justify-content-center px-8 px-lg-10">
          <div class="col-12">
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 class="mb-10 font-weight-bold text-dark">
                Karta malumotlari
              </h4>
              <div class="row flex-wrap">
                <div class="col-4">
                  <div class="form-group">
                    <label>Biriktiligan</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.owner_name"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label>Mashina raqami</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.owner_truck"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label>Karta raqami</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.number_16_digit"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label>Amal qilish muddati</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.expire | formatDate"
                    />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label>Qoldiq</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.remain"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Korporativ karta operatsiyalar
              </h4>
              <!-- <div
                  class="d-flex align-items-center justify-content-between"
                >
                  <div>
                  
                  </div>
                  <div>
                    <v-dialog v-model="card_add" persistent max-width="50%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          v-model="addCard"
                          color="success"
                          class="mr-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Kartani Tuldirish
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline"
                          >Kartani Tuldirish</v-card-title
                        >

                        <v-card-text rd-text>
                          <v-combobox
                            :value="getCorpCardDetails.number_16_digit"
                            label="Karta raqami"
                            outlined
                            disabled
                            item-text="number_16_digit"
                            dense
                          ></v-combobox>
                          <v-col cols="12" :class="'p-0'" sm="6" md="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Sana"
                                  append-icon="event"
                                  readonly
                                  dense
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="primary"
                                  @click="modal = false"
                                  >Bekor qilish</v-btn
                                >
                                <v-btn
                                  small
                                  color="success"
                                  @click="$refs.dialog.save(date)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          <v-text-field
                            label="Summa"
                            v-money="moneyDEPO"
                            dense
                            outlined
                            v-model.lazy="amountDEPO"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="card_add = false"
                            >Yopish</v-btn
                          >
                          <v-btn
                            color="green darken-1"
                            text
                            @click="submit('DEPO')"
                            >Yuborish</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="card_remove" persistent max-width="50%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small color="red" dark v-bind="attrs" v-on="on">
                          Kartadan Yechish
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline"
                          >Kartadan Yechish</v-card-title
                        >
                        <v-card-text rd-text>
                          <v-combobox
                            :value="getCorpCardDetails.number_16_digit"
                            label="Karta raqami"
                            outlined
                            item-text="number_16_digit"
                            dense
                          ></v-combobox>

                          <v-col cols="12" :class="'p-0'" sm="6" md="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Sana"
                                  append-icon="event"
                                  readonly
                                  dense
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="error"
                                  @click="modal = false"
                                  >Bekor qilish</v-btn
                                >
                                <v-btn
                                  small
                                  color="success"
                                  @click="$refs.dialog.save(date)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-text-field
                            label="Summa"
                            outlined
                            dense
                            v-money="moneyWITH"
                            v-model.lazy="amountWITH"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="card_remove = false"
                            >Yopish</v-btn
                          >
                          <v-btn
                            color="green darken-1"
                            text
                            @click="submit('WITH')"
                            >Yuborish</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div> -->
              <div class="mt-5 table__header">
                <div class="table__header-title">
                  <p>Kartani qoldig'i</p>
                </div>
                <div class="table__header-value">
                  <p>
                    {{ list.remain }}
                  </p>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="listtable"
                no-data-text="Malumot kiritilmagan"
                :items-per-page="5"
                single-select
                @click:row="rowClick"
                class="row-pointer"
                item-key="id"
              >
                <template v-slot:[`item.oper_date`]="{ item }">
                  {{ item.oper_date | formatDate }}
                </template>

                <template v-slot:[`item.operation_type`]="{ item }">
                  <div :class="item.operation_type_color">
                    <span
                      :class="item.operation_type_dot"
                      class="label label-dot"
                    ></span>
                    {{ item.operation_type }}
                  </div>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  {{ item.amount }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import KTWizard from '@/assets/js/components/wizard'
// import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  validations: {},
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      list: {},
      listtable: [],
      loading: true,
      amountDEPO: '',
      amountWITH: '',
      mmyycard: '',
      amount: '',
      moneyWITH: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        precision: '0',
        suffix: '',
        masked: false /* doesn't work with directive */
      },
      moneyDEPO: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        precision: '0',
        suffix: '',
        masked: false /* doesn't work with directive */
      },
      index: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      select: '',
      index2: '',
      menu2: false,
      card_add: false,
      addCardWith: 'WITH',
      removeCardDepo: 'DEPO',
      card_remove: false,
      search: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Karta raqami', value: 'card_number' },
        { text: 'Turi', value: 'operation_type' },
        { text: 'Muddat', value: 'oper_date' },
        { text: 'Debet', value: 'amountDebet' },
        { text: 'Kredit', value: 'amountCredet' }
      ],
      name: '',
      tab: null,
      items: [
        {
          tab: 'Karta malumotlari'
        },
        {
          tab: 'Karta operatsiyalari'
        }
      ]
    }
  },
  beforeCreate() {
    // this.$store.dispatch("getallCorpCardDetail", this.$route.params.id);
    this.$store.dispatch('getCorpCardsDetails', this.$route.params.id)
    this.$store.dispatch('getOperByCard', this.$route.params.id)
    this.$store.dispatch('getAllCorCards')
  },
  watch: {
    getCorpCardDetails(val) {
      this.list = val
    },
    getAllOperByCard(val) {
      this.listtable = [...val]
      this.loading = false
    },

    loading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  computed: {
    getCorpCardDetails() {
      return this.$store.state.requests.corpCardsDetails
    },
    // num16() {
    //   return this.$store.state.requests.corpCardsDetails.number_16_digit
    //     .replace(/(.{4})/g, "$1 ")
    //     .trim();
    // },
    // remamin() {
    //   const data = [];
    //   this.$store.state.requests.AllCorCards.forEach(element => {
    //     if (element.id == this.$route.params.id) {
    //       const data1 = {
    //         remain: element.remain.toLocaleString("es-US")
    //       };
    //       data.push(data1);
    //     }
    //   });
    //   return data;
    // },
    getAllOperByCard() {
      return this.$store.state.requests.operByCard
    }
    // amountError() {
    //   const errors = [];
    //   if (!this.$v.amountDEPO.$dirty) return errors;
    //   !this.$v.amountDEPO.required && errors.push("Summa toldirilishi shart.");
    //   return errors;
    // },
    //     amountError2() {
    //   const errors = [];
    //   if (!this.$v.amountWITH.$dirty) return errors;
    //   !this.$v.amountWITH.required && errors.push("Summa toldirilishi shart.");
    //   return errors;
    // }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        if (item.import_export_goods_services !== null) {
          this.$router.push(
            '/servicecashdetails/' + item.import_export_goods_services + '/'
          )
        }

        row.select(false)
      }
    },
    submit(value) {
      if (value == 'WITH') {
        const data = {
          operation_type: value,
          oper_date: this.date,
          amount: this.amountWITH.replace(/\./g, ' '),
          card: this.$route.params.id
        }
        this.oper_date = ''
        this.amountWITH = ''
        this.moneyWITH = ''

        this.select = ''
        this.card_add = false
        this.card_remove = false
        this.$store.dispatch('createConCardOper', data)
        // this.$v.$reset();
      } else if (value == 'DEPO') {
        const data = {
          operation_type: value,
          oper_date: this.date,
          amount: this.amountDEPO.replace(/\./g, ' '),
          card: this.$route.params.id
        }
        this.oper_date = ''
        this.amountDEPO = ''

        this.moneyDEPO = ''
        this.select = ''
        this.card_add = false
        this.card_remove = false
        this.$store.dispatch('createConCardOper', data)
        // this.$v.$reset();
      }
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitStatus = "ERROR";
      //   Swal.fire({
      //     title: "",
      //     text: "Ma'lumotlar yuborilmadi!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary"
      //   });
      // } else {

      // }
    },

    initWizard() {
      this.$nextTick(() => {
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1,
          clickableSteps: true
        })

        wizard.on('beforeNext', function (/*wizardObj*/) {})
      })
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: ' Korporativ karta ruyhati' },
      { title: 'Karta malumotlari' }
    ])
    this.initWizard()
  }
}
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.table__header {
  color: #000;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(51, 51, 51, 0.276);
  border-radius: 3px;
  padding: 20px;
  font-weight: bold;
}
.table__header p {
  margin: 0;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
}
.theme--light
  /deep/
  .v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #000 !important;
}

.theme--light /deep/ .v-label--is-disabled {
  color: #000 !important;
}
.theme--light /deep/ .v-input--is-disabled input {
  color: #000 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.wizard-step {
  padding: 0;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
